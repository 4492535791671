<template>
	<!-- 仓库资料 -->
	<div class="warehouseData">
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">操作日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="datetimerange" range-separator="至"
							start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd HH:mm:ss"
							:default-time="['00:00:00', '23:59:59']" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">操作模块:</div>
					<div class="item-input">
						<el-input v-model="searchForm.module" placeholder="请输入功能模块简称" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">操作内容:</div>
					<div class="item-input">
						<el-input v-model="searchForm.key" placeholder="请输入操作内容关键字" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click.stop="page = 1, searchForm.begin = dateVal[0] , searchForm.end = dateVal[1] , getList()">搜索</el-button>
					<el-button
						@click.stop="page = 1, pageNum = 10, searchForm = {}, dateVal = [getCurrentDate() + ' ' + '00:00:00', getCurrentDate() + ' ' + '23:59:59'],getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading" @size-change="pageNum = $event, getList()"
				@current-change="page = $event, getList()">
				<el-table-column label="操作时间" prop="created_at" align="center" width="160"></el-table-column>
				<el-table-column label="操作员" align="center" width="160">
					<template slot-scope="scope">
						<span v-if="scope.row.user_id == 0" style="color:green;">创建者</span>
						<span v-else style="color:red">{{scope.row.user_info}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作模块" prop="module" align="center" width="120"></el-table-column>
				<el-table-column label="操作内容" prop="content" align="center"></el-table-column>
			</Table>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				total: 0,
				page: 1,
				pageNum: 10,
				loading: true,
				searchForm: {
					begin: '',
					end: '',
					key: '',
					module: ''
				},
				dateVal: [],
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDate() + ' ' + '00:00:00', this.getCurrentDate() + ' ' + '23:59:59']
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			this.getList()
		},
		methods: {
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			getList() {
				this.loading = true
				this.$http.post(`/erp/v1/operate_log/lst`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>